import axios from "./axios";

export const getOrganizationDetails = async (websiteURL) => {
	try {
		const url = websiteURL;
		const res = await axios.get(`/org/scheduler/details`, {
			params: { url },
		});

		return res || "";
	} catch (e) {
		throw e;
	}
};


export const getOrganizationDetailsByCustomUrl = async (domain) => {
	try {
		const res = await axios.get(`/org/fetch/customurlbyurl/${domain}`);
		return res || "";
	} catch (e) {
		throw e;
	}
}