import axios from "./axios";

export const createCustomer = async (customerPayload) => {
	try {
		const { data } = await axios.post(
			`/scheduler/create/customer`,
			customerPayload
		);
		return data || null;
	} catch (e) {
		throw e;
	}
};

export const createCustomerwhatconverts = async (customerPayload) => {
	try {
		const { data } = await axios.post(
			`/scheduler/create/customerwhatconverts`,
			customerPayload
		);
		return data || null;
	} catch (e) {
		throw e;
	}
};

export const getCustomer = async (customerPayload) => {
	try {
		const { data } = await axios.post(
			`/scheduler/get/customer`,
			customerPayload
		);
		console.log("data", data);
		
		return data || null;
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getCustomerNew = async (customerPayload) => {
	try {
		const { data } = await axios.post(
			`/scheduler/get/customerNew`,
			customerPayload
		);

		return data || null;
	} catch (e) {
		console.log(e);
		throw e;
	}
};
